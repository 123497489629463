import { Grid, Paper, Stack, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";

const InteractionIssue = (props) => {
  const { row } = props;

  return (
    <>
      <div>
        <Stack
          sx={{ py: 2, height: "100%", boxSizing: "border-box" }}
          direction="column"
        >
          <Paper sx={{ flex: 1, mx: "auto", width: "100%", p: 1 }}>
            <Stack direction="column" spacing={1} sx={{ height: 1 }}>
              <Grid container direction={"column"}>
                {row.video_permission === false && (
                  <>
                    <Grid
                      item
                      md={2}
                      display={"flex"}
                      style={{ padding: "10px" }}
                    >
                      <Typography
                        variant="body2"
                        color="GrayText"
                        style={{ paddingRight: "5px" }}
                      >
                        Error type:
                      </Typography>
                      <Typography variant="body2" color="WindowText">
                        {row.video_permission === false && "Video permission"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      display={"flex"}
                      style={{ padding: "10px" }}
                    >
                      <Typography
                        variant="body2"
                        color="GrayText"
                        style={{ paddingRight: "5px" }}
                      >
                        Error description:
                      </Typography>
                      <Typography variant="body2" color="">
                        {" "}
                        Video permission is not given
                      </Typography>
                    </Grid>
                    <Divider />
                  </>
                )}
                {row.audio_permission === false && (
                  <>
                    <Grid
                      item
                      md={2}
                      display={"flex"}
                      style={{ padding: "10px" }}
                    >
                      <Typography
                        variant="body2"
                        color="GrayText"
                        style={{ paddingRight: "5px" }}
                      >
                        Error type:
                      </Typography>
                      <Typography variant="body2" color="WindowText">
                        {row.audio_permission === false && "Audio permission"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      display={"flex"}
                      style={{ padding: "10px" }}
                    >
                      <Typography
                        variant="body2"
                        color="GrayText"
                        style={{ paddingRight: "5px" }}
                      >
                        Error description:
                      </Typography>
                      <Typography variant="body2" color="">
                        {" "}
                        Audio permission is not given
                      </Typography>
                    </Grid>
                    <Divider />
                  </>
                )}
                {row.network_issue && (
                  <>
                    <Grid
                      item
                      md={4}
                      display={"flex"}
                      style={{ padding: "10px" }}
                    >
                      <Typography
                        variant="body2"
                        color="GrayText"
                        style={{ paddingRight: "5px" }}
                      >
                        Error type:
                      </Typography>
                      <Typography variant="body2" color="WindowText">
                        {row.network_issue}&nbsp;(Network Issue)
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      display={"flex"}
                      style={{ padding: "10px" }}
                    >
                      <Typography
                        variant="body2"
                        color="GrayText"
                        style={{ paddingRight: "5px" }}
                      >
                        Error description:
                      </Typography>
                      <Typography variant="body2" color="">
                        {" "}
                        {row.issue_reason}
                      </Typography>
                    </Grid>
                    <Divider />
                  </>
                )}
              </Grid>
            </Stack>
          </Paper>
        </Stack>
      </div>
    </>
  );
};

export default InteractionIssue;
